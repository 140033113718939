const PrivacyPolicy = () => {
  return (
    <>
      <section className="policy_content">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1>Privacy Policy</h1>
              <p>
                Collative provides work space on rent with guests seeking to
                rent such accommodations (collectively, the “Services”), which
                Services are accessible at www.collative.in and as its social
                media pages. This Privacy Policy describes how Collative
                collects and uses the personal information you provide on our
                web site www.collative.in. By using the Site and Application,
                you agree to comply with and be legally bound by the terms and
                conditions of these Terms of Service (“Terms”). These Terms
                govern your access to and use of the Site, Application and
                Services and all Collective Content, and your participation in
                the Program, and constitute a binding legal agreement between
                you and Collative. If you do not agree to these Terms, you have
                no right to obtain information from or otherwise continue using
                the Site or Application. Failure to use the Site and Application
                in accordance with these Terms may subject you to civil and
                criminal penalties.
              </p>

              <p>
                We value the trust you place in us. That is why we insist upon
                the highest standards for secure transactions and customer
                information privacy. Please read the following statement to
                learn about our information gathering and dissemination
                practices. This privacy policy also describes the choices
                available to you regarding use of your personal information and
                how you can access and update this information. By using,
                accessing or participating in the Service, you agree to the
                terms of this Privacy Policy.
              </p>

              <p>
                We reserve the right to change our Privacy Policy at any time
                without notice. To make sure you are aware of any changes,
                please review this policy periodically.
              </p>

              <p>
                By visiting this Website you agree to be bound by the terms and
                conditions of this Privacy Policy. If you do not agree please do
                not use or access our Website.
              </p>

              <p>
                By mere use of the Website, you expressly consent to our use and
                disclosure of your personal information in accordance with this
                Privacy Policy. This Privacy Policy is incorporated into and
                subject to the Terms of Use.
              </p>

              <p>
                Collection of Personally Identifiable Information and other
                Information
              </p>

              <p>
                When you use our Website, we collect and store your personal
                information which is provided by you from time to time. Our
                primary goal in doing so is to provide you a safe, efficient,
                smooth and customized experience. This allows us to provide
                services and features that most likely meet your needs, and to
                customize our Website to make your experience safer and easier.
                More importantly, while doing so we collect personal information
                from you that we consider necessary for achieving this purpose.
              </p>

              <h3>Personal and Sensitive Information</h3>

              <p>
                Personal Information means and includes all information that can
                be linked to a specific individual or to identify any
                individual, such as name, address, mailing address, telephone
                number, e – mail address, credit card number, cardholder name,
                expiration date, information about the bookings, and any and all
                details that may be requested while any customers visits or uses
                the website.
              </p>
              <p>
                Where you have consented to Collative use of “sensitive”
                Personal Information, which is used to uniquely identify you
                within the meaning of Information Technology (Reasonable
                security practices and procedures and sensitive personal data or
                information) (Rules, 2011) (“Sensitive Personal Information”),
                you are able to withdraw that consent at any time.
              </p>

              <p>
                Prior to disclosing Sensitive Personal Information to a third
                party or Processing Sensitive Data for a purpose other than its
                original purpose or the purpose authorized subsequently by the
                Data Subject, Collative will endeavour to obtain your explicit
                consent (opt-in). Where your consent for the Processing of
                Personal Information is otherwise required by law or contract,
                Collative will comply with the law or contract.
              </p>
              <p>
                When you enter the Site, we collect your browser type and IP
                address. This information is gathered for all Collative
                visitors. In addition, we store certain information from your
                browser using “cookies.” The Website uses cookie and tracking
                technology depending on the features offered. We use data
                collection devices such as “cookies” on certain pages of the
                Website to help analyse our web page flow, measure promotional
                effectiveness, and promote trust and safety. “Cookies” are small
                files placed on your hard drive that assist us in providing our
                services. We offer certain features that are only available
                using a “cookie”.
              </p>

              <p>
                We also use cookies to allow you to enter your password less
                frequently during a session. Cookies can also help us provide
                information that is targeted to your interests. Most cookies are
                “session cookies,” meaning that they are automatically deleted
                from your hard drive at the end of a session. You are always
                free to decline our cookies if your browser permits, although in
                that case you may not be able to use certain features on the
                Website and you may be required to re-enter your password more
                frequently during a session.
              </p>

              <p>
                Additionally, you may encounter “cookies” or other similar
                devices on certain pages of the Website that are placed by third
                parties. We do not control the use of cookies by third parties.
              </p>

              <p>
                First and foremost, you can rest assured that no personally
                identifiable information (“PII”) about you (e.g., name, address,
                etc.) is gathered or stored in the cookies placed by the Website
                and, as a result, none can be passed on to any third parties.
              </p>

              <p>
                A cookie may also be placed by our advertising server. Such
                cookies are used only for purposes of tracking the effectiveness
                of advertising served by us on our Website and no PII is
                gathered from you using these cookies, nor is this information
                shared with any third parties. Similarly, a cookie may be placed
                by our third – party advertising companies or advertisement
                providers or servers. These companies may use aggregated
                statistics about your visits to this and other websites in order
                to provide advertisements about work space – related goods and
                services that you may be interested in or any goods and services
                of potential interest to you. The information they collect does
                not include your PII.
              </p>

              <p>
                The third – party advertising companies or advertisement
                providers may also employ technology that is used to measure the
                effectiveness of ads. Any such information is anonymous. They
                may use this anonymous information about your visits to this and
                other sites in order to provide advertisements about goods and
                services of potential interest to you. No PII is collected
                during this process. The information is anonymous and does not
                link online actions to an identifiable person. If you would like
                more information and want to explore the option of not having
                this information collected by third – party advertisers.
              </p>

              <p>
                Most Web browsers automatically accept cookies. Of course, by
                changing the options on your web browser or using certain
                software programs, you can control how and whether cookies will
                be accepted by your browser. Collative supports your right to
                block any unwanted Internet activity, especially that of
                unscrupulous websites. However, blocking Collative cookies may
                disable certain features on the Website, and may make it
                impossible to book or use certain services available on the
                Website. Please note that it is possible to block cookie
                activity from certain websites while permitting cookies from
                websites you trust, like Collative Website.
              </p>

              <h3>Other</h3>

              <p>
                From time to time we may add or enhance services available on
                the Website. To the extent these services are provided, and used
                by you, we will use the information you provide to facilitate
                the service requested. For example, if you email us with a
                question, we will use your email address, name, nature of the
                question, etc. to respond to your question. We may also store
                such information to assist us in making the Website the better
                and easier to use.
              </p>

              <p>
                Through the registration process you may provide us with your
                name, company affiliation, email address, telephone number,
                physical address, and other information that may be requested
                during the registration process on the Site. If you wish to
                create an account that requires payment of a fee, or use
                services offered via the Site that require payment of a fee, a
                valid credit card number or other payment information may be
                required. By submitting your payment information, you authorize
                Collative to access and use this information in order to provide
                you with and accept payment for the Service.
              </p>

              <p>
                When you use the Service you may submit information and content
                to your Profile, send messages, perform searches, submit
                reviews, and otherwise transmit information to other Users. We
                store this information and registration information so that we
                can provide you the service and offer personalized features.
              </p>

              <p>
                We are not responsible for circumvention of any privacy settings
                or security measures contained on the Site. You understand and
                acknowledge that, even after removal, copies of User Content may
                remain viewable in cached and archived pages or if other Users
                have copied or stored your User Content.
              </p>

              <p>
                In general, you can browse the Website without telling us who
                you are or revealing any personal information about yourself.
                Once you give us your personal information, you are not
                anonymous to us. Where possible, we indicate which fields are
                required and which fields are optional. You always have the
                option to not provide information by choosing not to use a
                particular service or feature on the Website. We may
                automatically track certain information about you based upon
                your behaviour on our Website. We use this information to do
                internal research on our users’ demographics, interests, and
                behaviour to better understand, protect and serve our users.
                This information is compiled and analysed on an aggregated
                basis. This information may include the URL that you just came
                from (whether this URL is on our Website or not), which URL you
                next go to (whether this URL is on our Website or not), your
                computer browser information, and your IP address.
              </p>

              <p>
                If you transact with us, we collect some additional information,
                such as a billing address, a credit / debit card number and a
                credit / debit card expiration date and/ or other payment
                instrument details and tracking information from cheques or
                money orders.
              </p>

              <p>
                If you choose to post messages on our message boards, chat rooms
                or other message areas or leave feedback, we will collect that
                information you provide to us. We retain this information as
                necessary to resolve disputes, provide customer support and
                troubleshoot problems as permitted by law.
              </p>

              <h3>Information with Third Parties</h3>

              <p>
                When you use the Service you may submit information and content
                to your Profile, send messages, perform searches, submit
                reviews, and otherwise transmit information to other Users. We
                store this information and registration information so that we
                can provide you the service and offer personalized features.
              </p>

              <p>
                If you use the Mobile Software, your location may be tracked on
                the mobile device on which the Mobile Software is installed.
                Collative will not collect, transmit or otherwise use this
                information without your permission. We use location data to
                provide you relevant search results for spaces in your area. As
                a Member, you can control when Venue Owners or other Members can
                see your location through your privacy settings. To opt out of
                location tracking, please change your settings at the device
                level.
              </p>

              <p>
                When you download and use our Mobile Software, we automatically
                collect information on the type of device you use, operating
                system version, and the device identifier.
              </p>

              <h3>Use of Information Obtained by Collative</h3>

              <p>
                Once you register with Collative, you may create your Profile.
                Your Profile information, as well as your contact information,
                are displayed to other Users. We may occasionally use your
                contact information to send you notifications regarding new
                services offered by Collative and its partners that we think you
                may find valuable. Collative may also send you service-related
                announcements from time to time through the general operation of
                the Service. Generally, you may opt out of such emails by
                following the unsubscribe option included in each email,
                although Collative reserves the right to send you notices about
                your account even if you opt out of all voluntary email
                notifications.
              </p>

              <p>
                Profile information and your reservation activity is used by
                Collative primarily to be presented back to and edited by you
                when you access the Service. In some cases, other Users may be
                able to supplement your Profile or contribute information about
                your Venue (if you are a Venue Owner), including by submitting
                comments and reviews. You consent to Collative making such
                information available to other Users and the public. You may
                change the privacy settings of your profile through your account
                portal. Information from your Profile may be available to our
                third party service provider for search engines. This is
                primarily so that potential users may be able to find you.
              </p>

              <h3>Sharing Your Information with Third Parties</h3>

              <p>
                You may choose to provide information about you, your company or
                organization or, if you are a Venue Owner, your Venue to other
                Users and to the public by providing such information on public
                areas of the Site (“Public Information”). Public Information
                consists of information that is posted by you on areas of the
                Site accessible by other Users and/or the public, such as Venue
                descriptions. Public information does not include information
                you submit to us but do not post on areas of the Site accessible
                by other Users and/or the public, such as billing and payment
                information (“Non-Public Information”).
              </p>

              <p>
                We will share your personal information with third parties only
                in the ways that are described in this privacy policy. We do not
                sell your personal information to third parties. We share your
                Non-Public Information with third parties only in limited
                circumstances where we believe such sharing is reasonably
                necessary to offer the Service, legally required or, permitted
                by you. For example:
              </p>
              <p>
                We may provide Non-Public Information to service providers who
                help us bring you the services we offer, such as hosting the
                service at a co-location facility, sending email updates about
                Collative, removing repetitive information from our User lists,
                processing payments, or to provide search results or links. In
                connection with these offerings and business operations, our
                service providers may have access to your Non-Public Information
                for use for a limited time in connection with these business
                activities. Where we utilize third parties for the processing of
                any of your Non-Public Information, we implement reasonable
                contractual protections limiting the use of that Non-Public
                Information to the provision of services to Collative.
              </p>

              <p>
                In addition to the circumstances described above , Collative may
                disclose the website member information if required to do so by
                the law, court order, as requested by other government or law
                enforcement authority, or in the good faith belief that
                disclosure is otherwise necessary or advisable including,
                without limitation, to protect the rights or properties of
                Collative or any or all of its affiliates, associates,
                employees, directors or officers or when we have reason to
                believe that disclosing the information is necessary to
                identify, contact or bring legal action against someone who may
                be causing interference with our rights or properties, whether
                intentionally or otherwise, or when anyone else could be harmed
                by such activities. In addition, if Collative or substantially
                all of its assets are acquired, our customer information will
                most likely also be transferred in connection with such
                acquisition.
              </p>

              <p>
                We and our affiliates will share/ sell some or all of your
                personal information with another business entity should we (or
                our assets) plan to merge with, or be acquired by that business
                entity, or re-organisation, amalgamation, restructuring of
                business. Should such a transaction occur that other business
                entity (or the new combined entity) will be required to follow
                this privacy policy with respect to your personal information.
              </p>

              <h3>Use of Demographic / Profile Data / Your Information</h3>

              <p>
                We use personal information to provide the services you request.
                To the extent we use your personal information to market to you,
                we will provide you the ability to opt-out of such uses. We use
                your personal information to resolve disputes; troubleshoot
                problems; help promote a safe service; collect money; measure
                consumer interest in our products and services, inform you about
                online and offline offers, products, services, and updates;
                customize your experience; detect and protect us against error,
                fraud and other criminal activity; enforce our terms and
                conditions; and as otherwise described to you at the time of
                collection.
              </p>

              <p>
                In our efforts to continually improve our product and service
                offerings, we collect and analyse demographic and profile data
                about our users’ activity on our Website.
              </p>

              <p>
                We identify and use your IP address to help diagnose problems
                with our server, and to administer our Website. Your IP address
                is also used to help identify you and to gather broad
                demographic information.
              </p>

              <p>
                We will occasionally ask you to complete optional online
                surveys. These surveys may ask you for contact information and
                demographic information (like age, or income level). We use this
                data to tailor your experience at our Website, providing you
                with content that we think you might be interested in and to
                display content according to your preferences.
              </p>

              <h3>Links to Other Sites</h3>
              <p>
                Our website links to other website that may collect personally
                identifiable information about you. Please note that when you
                click on one of these links, you are entering another website
                for which Collative has no responsibility. We encourage you to
                read the privacy statement of all such sites as their policies
                may be materially different from this privacy policy. Of course,
                you are solely responsible for maintaining the secrecy of your
                password, and your website membership account information.
                Please be very careful, responsible and alert with this
                information, especially whenever you are online.
                www.collative.in is not responsible for the privacy practices or
                the content of those linked websites.
              </p>

              <h3>Single Sign-on</h3>

              <p>
                You can log in to our site using sign-in services such as an
                Open ID provider. These services will authenticate your identity
                and provide you the option to share certain personal information
                with us such as your name and email address to pre-populate our
                sign-up form. Services like Linked In Open ID provider give you
                the option to post information about your activities on this web
                site to your profile page to share with others within your
                network.
              </p>

              <h3>Social Media Widgets</h3>
              <p>
                Our web site includes social media features, such as the
                Facebook Like button and widgets, such as the Share This button
                or interactive mini-programs that run on our site. These
                features may collect your IP address, which page you are
                visiting on our site, and may set a cookie to enable the feature
                to function properly. Social media features and widgets are
                either hosted by a third party or hosted directly on our site.
                Your interactions with these features are governed by the
                privacy policy of the company providing it.
              </p>

              <h3>Online Surveys</h3>

              <p>
                Collative values Security Precaution opinions and comments from
                members, so we frequently conduct online surveys. Participation
                in these surveys is entirely optional. Typically, the
                information is aggregated, and used to make improvements to the
                Website and its services and to develop appealing content,
                features and promotions for Website members. Survey participants
                are anonymous unless otherwise stated in the survey.
              </p>

              <h3>Security Precaution</h3>

              <p>
                Our Website has stringent security measures in place to protect
                the loss, misuse, and alteration of the information under our
                control. Whenever you change or access your account information,
                we offer the use of a secure server. Once your information is in
                our possession we adhere to strict security guidelines,
                protecting it against unauthorized access. However no method of
                transmission over the internet, or the method of electronic
                storage, is 100% secure. Therefore we cannot guarantee absolute
                security. If you have any questions about security please
                contact us.
              </p>

              <h3>Choice/Opt-Out</h3>

              <p>
                We provide all users with the opportunity to opt-out of
                receiving non-essential (promotional, marketing-related)
                communications from us on behalf of our partners, and from us in
                general, after setting up an account.
              </p>
              <p>
                If you want to remove your contact information from all
                www.(.).comlists and newsletters, please visit unsubscribe
                Advertisements on collative.in
              </p>
              <p>
                We use third-party advertising companies to serve ads when you
                visit our Website. These companies may use information (not
                including your name, address, email address, or telephone
                number) about your visits to this and other websites in order to
                provide advertisements about goods and services of interest to
                you.
              </p>

              <h3>Grievance Redressal</h3>

              <p>
                If you have any grievances in this regard, please contact
                Mr.(.)(Grievance Officer) at the following mail ID:
              </p>

              <p>Privacy Email: enquiry@collative.in</p>

              <p>
                Collative will address your grievances/concerns and attempt to
                resolve any privacy issues in a timely manner.
              </p>

              <h3>Your Consent</h3>

              <p>
                By using the Website and/ or by providing your information, you
                consent to the collection and use of the information you
                disclose on the Website in accordance with this Privacy Policy,
                including but not limited to Your consent for sharing your
                information as per this privacy policy.
              </p>
              <p>
                If you have any questions about this Privacy Policy or
                Collative’s privacy practices in general, please contact us as
                provided for below. You may also contact us to require that we
                provide you with a copy of your Non-Public Information within
                our control, to have us correct any erroneous information, to
                discontinue receipt of certain types of communications or to ask
                us to delete any of your Non-Public Information we have stored
                that is no longer required for the purpose for which it was
                collected. We will respond to your request to access non public
                information within 30 days.
              </p>

              <h3>Use, Retention and Disposal</h3>

              <p>
                Personal information shall be retained for as long as necessary
                for business purposes identified in the notice / SoW / contract
                agreements at the time of collection or subsequently authorized
                by the data subjects. Collative shall not retain personal
                information longer than is necessary to fulfil the purposes for
                which it was collected and to maintain reasonable business
                records
              </p>

              <h3>Security</h3>

              <p>
                Information security policy and procedures shall be documented
                and implemented to ensure reasonable security for personal
                information collected, stored, used, transferred and disposed by
                Collative. Collative shall ensure at all times that such
                Sensitive Personal Information shall remain secured at all
                times, within the meaning of this Privacy Policy.
              </p>

              <p>
                Information asset labelling and handling guidelines shall
                include controls specific to the storage, retention and transfer
                of personal information
              </p>
              <p>
                Management shall establish procedures that maintain the logical
                and physical security of personal information.
              </p>
              <p>
                Management shall establish procedures that ensure protection of
                personal information against accidental disclosure due to
                natural disasters and environmental hazards.
              </p>

              <p>
                Incident response protocols are established and maintained in
                order to deal with incidents concerning personal data or privacy
                practices.
              </p>
              <p>
                Individuals noticing or becoming aware of any breach of personal
                data shall notify the DPO (by emailing at
                grievance@collative.com) within 2 hours. It shall be the DPO’s
                responsibility to analyse and act on the intimation of the same
                within 12 hours; furthermore in accordance with the Breach
                Management Policy (wherever applicable)
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PrivacyPolicy;